import { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { FiArrowLeft, FiChevronsRight, FiPlay } from 'react-icons/fi';
import { Transition, Dialog } from '@headlessui/react';

import Header from '../../components/Header';
import SmallScreen from '../../components/SmallScreen';
import Api from '../../configs/Api';
import './shapes.css';

const STEP = {
  ChooseBackpackCollection: 0,
  ChooseBackpack: 1,
}

const BackpackListPage = () => {
  const navigate = useNavigate();

  const [collections, setCollections] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [step, setStep] = useState(STEP.ChooseBackpackCollection);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedBackpack, setSelectedBackpack] = useState(null);
  const [hoveredPack, setHoveredPack] = useState(null);
  const [isShowIntroModal, setIsShowIntroModal] = useState(false);
  const [intro_video_url, setintro_video_url] = useState('');

  useEffect(() => {
    Api.get(`/collections`)
    .then((response) => {
      setCollections(response.data);
      setTimeout(() => {
        // setIsLoading(false);
      }, 500);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  const onClickBackpack = (name) => {
    setSelectedBackpack(selectedCollection.backpacks.filter((backpack) => backpack.name === name)[0]);
  }

  const renderLifewidePack = (collections) => {
    const PACK_NAME = 'Lifewide Experience and Lifelong Learning';
    const collection = collections.filter((collection) => collection.name === PACK_NAME)[0];

    if (selectedCollection.name !== PACK_NAME) return;

    return (
      <div className='flex px-40'>
        <div className="w-2/3">
          <div className="star-chart">
            {collection.backpacks.map(({ name }, index) => (
              <div key={`star-${index}`} className={`star-slice star-slice-${index+1} cursor-pointer`} onClick={() => onClickBackpack(name)}>
                <div className={`${hoveredPack && hoveredPack === name && !selectedBackpack && 'active'} ${selectedBackpack && selectedBackpack.name === name && 'active'} star-slice-triangle`}></div>
              </div>
            ))}
          </div>
        </div>

        {!selectedBackpack && (
          <div className='w-1/3 flex justify-center items-center'>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>Select a pack</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-8'>Click on a pack to see more information</p>

              <div className='flex flex-col items-center'>
                {collection.backpacks.map(({ name }, index) => (
                  <div
                    key={`list-${index}`}
                    className='font-semibold cursor-pointer text-center hover:underline mb-2'
                    onClick={() => onClickBackpack(name)}
                    onMouseOver={() => setHoveredPack(name)}
                    onMouseOut={() => setHoveredPack(null)}
                  >{name}</div>
                ))}
              </div>
            </div>
          </div>
        )}


        {selectedBackpack && (
          <div className={`w-1/3 flex justify-center items-center`}>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>{selectedBackpack.name}</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-12'>{selectedBackpack.description}</p>
              <div
                style={{ 'backgroundColor': selectedBackpack.color }}
                className='flex justify-center items-center w-2/3 bg-gray-200 p-3 font-semibold text-gray-500 rounded-xl cursor-pointer bg-indigo-500 text-white'
                onClick={() => navigate(`/collections/${selectedCollection.id}/backpacks/${selectedBackpack.id}`)}
              >
                Explore
                <FiChevronsRight size={20} className="ml-1" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  const renderSocialActionPack = () => {
    const PACK_NAME = 'Social Action and Communities';
    const collection = collections.filter((collection) => collection.name === PACK_NAME)[0];

    if (selectedCollection.name !== PACK_NAME) return;

    return (
      <div className='flex px-40'>
        <div className="w-2/3">
          <div className="pentagon-chart">
            {collection.backpacks.map(({ name }, index) => (
              <div key={`pentagon-${index}`} className={`pentagon-slice pentagon-slice-${index+1} cursor-pointer`} onClick={() => onClickBackpack(name)}>
                <div className={`${hoveredPack && hoveredPack === name && !selectedBackpack && 'active'} ${selectedBackpack && selectedBackpack.name === name && 'active'} pentagon-slice-triangle`}></div>
              </div>
            ))}
          </div>
        </div>

        {!selectedBackpack && (
          <div className='w-1/3 flex justify-center items-center'>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>Select a pack</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-8'>Click on a pack to see more information</p>

              <div className='flex flex-col items-center'>
                {collection.backpacks.map(({ name }, index) => (
                  <div
                    key={`list-${index}`}
                    className='font-semibold cursor-pointer text-center hover:underline mb-2'
                    onClick={() => onClickBackpack(name)}
                    onMouseOver={() => setHoveredPack(name)}
                    onMouseOut={() => setHoveredPack(null)}
                  >{name}</div>
                ))}
              </div>
            </div>
          </div>
        )}

        {selectedBackpack && (
          <div className={`w-1/3 flex justify-center items-center`}>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>{selectedBackpack.name}</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-12'>{selectedBackpack.description}</p>
              <div
                style={{ 'backgroundColor': selectedBackpack.color}}
                className='flex justify-center items-center w-2/3 bg-gray-200 p-3 font-semibold text-gray-500 rounded-xl cursor-pointer bg-indigo-500 text-white'
                onClick={() => navigate(`/collections/${selectedCollection.id}/backpacks/${selectedBackpack.id}`)}
              >
                Explore
                <FiChevronsRight size={20} className="ml-1" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  const renderWorkerPack = () => {
    const PACK_NAME = 'Skills, Competencies and Specialisms for cultural workers and students';
    const collection = collections.filter((collection) => collection.name === PACK_NAME)[0];

    if (selectedCollection.name !== PACK_NAME) return;

    return (
      <div className='flex px-40'>
        <div className="w-2/3">
          <div className="nonagon-chart">
            {collection.backpacks.map(({ name }, index) => (
              <div key={`nonagon-${index}`} className={`nonagon-slice nonagon-slice-${index+1} cursor-pointer`} onClick={() => onClickBackpack(name)}>
                <div className={`${hoveredPack && hoveredPack === name && !selectedBackpack && 'active'} ${selectedBackpack && selectedBackpack.name === name && 'active'} nonagon-slice-triangle`}></div>
              </div>
            ))}
          </div>
        </div>

        {!selectedBackpack && (
          <div className='w-1/3 flex justify-center items-center'>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>Select a pack</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-8'>Click on a pack to see more information</p>

              <div className='flex flex-col items-center'>
                {collection.backpacks.map(({ name }, index) => (
                  <div
                    key={`list-${index}`}
                    className='font-semibold cursor-pointer text-center hover:underline mb-2'
                    onClick={() => onClickBackpack(name)}
                    onMouseOver={() => setHoveredPack(name)}
                    onMouseOut={() => setHoveredPack(null)}
                  >{name}</div>
                ))}
              </div>
            </div>
          </div>
        )}

        {selectedBackpack && (
          <div className={`w-1/3 flex justify-center items-center`}>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>{selectedBackpack.name}</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-12'>{selectedBackpack.description}</p>
              <div
                style={{ 'backgroundColor': selectedBackpack.color}}
                className='flex justify-center items-center w-2/3 bg-gray-200 p-3 font-semibold text-gray-500 rounded-xl cursor-pointer bg-indigo-500 text-white'
                onClick={() => navigate(`/collections/${selectedCollection.id}/backpacks/${selectedBackpack.id}`)}
              >
                Explore
                <FiChevronsRight size={20} className="ml-1" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  const renderSustainablePack = () => {
    const PACK_NAME = 'Education for Sustainable Development (UNESCO cross-cutting competencies)';
    const collection = collections.filter((collection) => collection.name === PACK_NAME)[0];

    if (selectedCollection.name !== PACK_NAME) return;

    return (
      <div className='flex px-40'>
        <div className="w-2/3">
          <div className="donut-chart">
            {collection.backpacks.map(({ name }, index) => (
              <div key={`pie-${index}`} id={`pie-slice-${index+1}`} className="hold cursor-pointer" onClick={() => onClickBackpack(name)}>
                <div className={`${hoveredPack && hoveredPack === name && !selectedBackpack && 'active'} ${selectedBackpack && selectedBackpack.name === name && 'active'} pie`}></div>
              </div>
            ))}
            <p className="center"></p>
          </div>
        </div>

        {!selectedBackpack && (
          <div className='w-1/3 flex justify-center items-center'>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>Select a pack</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-8'>Click on a pack to see more information</p>

              <div className='flex flex-col items-center'>
                {collection.backpacks.map(({ name }, index) => (
                  <div
                    key={`list-${index}`}
                    className='font-semibold cursor-pointer text-center hover:underline mb-2'
                    onClick={() => onClickBackpack(name)}
                    onMouseOver={() => setHoveredPack(name)}
                    onMouseOut={() => setHoveredPack(null)}
                  >{name}</div>
                ))}
              </div>
            </div>
          </div>
        )}

        {selectedBackpack && (
          <div className={`w-1/3 flex justify-center items-center`}>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>{selectedBackpack.name}</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-12'>{selectedBackpack.description}</p>
              <div
                style={{ 'backgroundColor': selectedBackpack.color }}
                className='flex justify-center items-center w-2/3 bg-gray-200 p-3 font-semibold text-gray-500 rounded-xl cursor-pointer bg-indigo-500 text-white'
                onClick={() => navigate(`/collections/${selectedCollection.id}/backpacks/${selectedBackpack.id}`)}
              >
                Explore
                <FiChevronsRight size={20} className="ml-1" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  const renderTransculturalPack = () => {
    return (
      <></>
    );
  }
  const renderCreativeAttributesFramework = () => {
    const PACK_NAME = 'Creative Attributes Framework';
    const collection = collections.filter((collection) => collection.name === PACK_NAME)[0];

    if (selectedCollection.name !== PACK_NAME) return;

    return (
      <div className='flex px-40'>
        <div className="w-2/3">
          <div className="nonagon2-chart">
            {collection.backpacks.map(({ name }, index) => (
              <div key={`nonagon2-${index}`} className={`nonagon2-slice nonagon2-slice-${index+1} cursor-pointer`} onClick={() => onClickBackpack(name)}>
                <div className={`${hoveredPack && hoveredPack === name && !selectedBackpack && 'active'} ${selectedBackpack && selectedBackpack.name === name && 'active'} nonagon2-slice-triangle`}></div>
              </div>
            ))}
          </div>
        </div>

        {!selectedBackpack && (
          <div className='w-1/3 flex justify-center items-center'>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>Select a pack</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-8'>Click on a pack to see more information</p>

              <div className='flex flex-col items-center'>
                {collection.backpacks.map(({ name }, index) => (
                  <div
                    key={`list-${index}`}
                    className='font-semibold cursor-pointer text-center hover:underline mb-2'
                    onClick={() => onClickBackpack(name)}
                    onMouseOver={() => setHoveredPack(name)}
                    onMouseOut={() => setHoveredPack(null)}
                  >{name}</div>
                ))}
              </div>
            </div>
          </div>
        )}

        {selectedBackpack && (
          <div className={`w-1/3 flex justify-center items-center`}>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>{selectedBackpack.name}</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-12'>{selectedBackpack.description}</p>
              <div
                style={{ 'backgroundColor': selectedBackpack.color}}
                className='flex justify-center items-center w-2/3 bg-gray-200 p-3 font-semibold text-gray-500 rounded-xl cursor-pointer bg-indigo-500 text-white'
                onClick={() => navigate(`/collections/${selectedCollection.id}/backpacks/${selectedBackpack.id}`)}
              >
                Explore
                <FiChevronsRight size={20} className="ml-1" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  const renderInterculturalCooperation = () => {
    const PACK_NAME = 'Transcultural and Collaborative Practices (UNESCO Intercultural Competencies)';
    const collection = collections.filter((collection) => collection.name === PACK_NAME)[0];

    if (selectedCollection.name !== PACK_NAME) return;

    return (
      <div className='flex px-40'>
        <div className="w-2/3">
          <div className='flex justify-center items-center pt-20'>
            <InterculturalShape backpacks={collection.backpacks} xxx={onClickBackpack} />
          </div>
        </div>

        {!selectedBackpack && (
          <div className='w-1/3 flex justify-center items-center'>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>Select a pack</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-8'>Click on a pack to see more information</p>

              <div className='flex flex-col items-center'>
                {collection.backpacks.map(({ name }, index) => (
                  <div
                    key={`list-${index}`}
                    className='font-semibold cursor-pointer text-center hover:underline mb-2'
                    onClick={() => onClickBackpack(name)}
                    onMouseOver={() => setHoveredPack(name)}
                    onMouseOut={() => setHoveredPack(null)}
                  >{name}</div>
                ))}
              </div>
            </div>
          </div>
        )}

        {selectedBackpack && (
          <div className={`w-1/3 flex justify-center items-center`}>
            <div className='bg-white rounded-lg shadow-lg p-8 flex flex-col items-center mb-20'>
              <h1 className='font-[AvenirBlack] text- gray-800 text-2xl font-semibold text-center mb-2'>{selectedBackpack.name}</h1>
              <p className='font-[AvenirBook] text-gray-600 text-center mb-12'>{selectedBackpack.description}</p>
              <div
                style={{ 'backgroundColor': selectedBackpack.color}}
                className='flex justify-center items-center w-2/3 bg-gray-200 p-3 font-semibold text-gray-500 rounded-xl cursor-pointer bg-indigo-500 text-white'
                onClick={() => navigate(`/collections/${selectedCollection.id}/backpacks/${selectedBackpack.id}`)}
              >
                Explore
                <FiChevronsRight size={20} className="ml-1" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  const onClickPreselectedPack = (collection) => {
    setSelectedCollection(collection);
    setStep(STEP.ChooseBackpack);
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 500);
  };

  const PreselectedPackIntroVideo = () => {
    const url = `https://www.youtube.com/embed/${intro_video_url.split('/').pop()}?autoplay=1`;

    return (
      <Transition appear show={isShowIntroModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsShowIntroModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-screen w-screen lg:w-3/4 max-h-screen h-[600px] lg:h-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <iframe width="100%" height="100%" src={url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen></iframe>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }

  const InterculturalShape = (backpacks, xxx) => {
    console.log(backpacks, xxx, typeof xxx);

    backpacks = backpacks.backpacks;
    const DEFAULT_COLOR = '#e6e6e6';
    const [triangles, setTriangles] = useState([
      { points: "0,20 0,0 10,10", fill: backpacks[0].color, is_active: false }, // "#604E80"
      { points: "20,20 0,20 10,10", fill: backpacks[1].color, is_active: false }, // "#C09CFF"
      { points: "0,20 20,20 10,30", fill: backpacks[2].color, is_active: false }, // "#733FCC"
      { points: "0,40 0,20 10,30", fill: backpacks[3].color, is_active: false }, // "#8F50FF"

      { points: "20,40 0,40 10,30", fill: backpacks[4].color, is_active: false }, // "#747A46"
      { points: "20,20 20,40 10,30", fill: backpacks[5].color, is_active: false }, // "#EFF5B8"
      { points: "20,40 20,20 30,30", fill: backpacks[6].color, is_active: false }, // "#A1AD40"
      { points: "40,40 20,40 30,30", fill: backpacks[7].color, is_active: false }, // "#CAE027"

      { points: "20,0 40,0 30,10", fill: backpacks[8].color, is_active: false }, // "#327A6D"
      { points: "20,20 20,0 30,10", fill: backpacks[9].color, is_active: false }, // "#AFEDE1"
      { points: "20,0 20,20 10,10", fill: backpacks[10].color, is_active: false }, // "#24AD94"
      { points: "0,0 20,0 10,10", fill: backpacks[11].color, is_active: false }, // "#A8FFCF"

      { points: "40,20 40,40 30,30", fill: backpacks[12].color, is_active: false }, // "#632828"
      { points: "20,20 40,20 30,30", fill: backpacks[13].color, is_active: false }, // "#EBA4A4"
      { points: "40,20 20,20 30,10", fill: backpacks[14].color, is_active: false }, // "#8A0413"
      { points: "40,0 40,20 30,10", fill: backpacks[15].color, is_active: false }, //"#D30000"
    ]);


    const updateStatus = (index) => {
      let newTriangles = [...triangles];
      newTriangles.forEach((triangle) => (triangle.is_active = false));
      newTriangles[index].is_active = !newTriangles[index].is_active;
      setTriangles(newTriangles);
      console.log(xxx, typeof xxx)
      // onClickBackpack(backpacks[index].name);
    };

    return (
      <svg width="400" height="400" viewBox="0 0 40 40">
        {triangles.map((triangle, index) => (
          <polygon
            key={index}
            className='cursor-pointer'
            points={triangle.points}
            fill={triangle.is_active ? triangle.fill : DEFAULT_COLOR}
            stroke='rgb(243, 244, 246)'
            strokeWidth='0.2'
            onClick={() => updateStatus(index)}
          />
        ))}
      </svg>
    );
  };


  return (
    <div>
      <PreselectedPackIntroVideo />

      <Header isFixedPosition={true} />

      <SmallScreen />

      <div className={`container mt-36 mb-20 flex-col justify-center transition duration-300 ${step === STEP.ChooseBackpackCollection ? 'opacity-100' : 'opacity-0 -translate-x-4'} ${step === STEP.ChooseBackpack && !isAnimating ? 'hidden' : 'block'}`}>
        <div className='flex justify-between items-center mb-12'>
          <div className='cursor-pointer text-gray-500 flex justify-center items-center' onClick={() => { navigate(-1); setSelectedBackpack(null); setHoveredPack(null); }}>
            <FiArrowLeft size={20} className="mr-1" />
            Back
          </div>
          <h1 className={`font-[AvenirBlack] text-2xl font-semibold text-center  ounded-xl`}>All preselected packs</h1>
          <div></div>
        </div>

        <div className='flex justify-center'>
          <div className='px-20 w-5/6 grid grid-cols-3 gap-4 place-content-center'>
            {collections.length !== 0 && collections.map((collection) => (
              <div
                key={`collection-${collection.id}`}
                className='z-1 bg-white rounded-xl p-8 flex flex-col items-center cursor-pointer hover:shadow-xl hover:opacity-100 transition duration-300'
                onClick={() => onClickPreselectedPack(collection)}
              >
                <img src={collection.image} alt='' className='rounded-lg mb-4'  />
                <h3 className='font-[AvenirBlack] mb-2 text-xl text-center'>{collection.display_name}</h3>
                {collection.intro_video_url && (
                  <div
                    className='flex items-center cursor-pointer hover:underline mb-2 z-10'
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsShowIntroModal(true);
                      setintro_video_url(collection.intro_video_url);
                    }}
                  >
                    <FiPlay className='mr-2' />
                      Introduction
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={`container mt-36 transition duration-300 ${step === STEP.ChooseBackpack && !isAnimating ? 'opacity-100 -translate-x-4' : 'fixed opacity-0'}`}>
        <div className='flex justify-between items-center mb-20'>
          <div className='cursor-pointer text-gray-500 flex justify-center items-center' onClick={() => { setSelectedBackpack(null); setStep(STEP.ChooseBackpackCollection); setIsAnimating(true); }}>
            <FiArrowLeft size={20} className="mr-1" />
            Back
          </div>
          <h1 className={`font-[AvenirBlack] text-2xl font-semibold text-center  ounded-xl`}>{selectedCollection && selectedCollection.name}</h1>
          <div></div>
        </div>

        {selectedCollection && (
          <>
            {renderLifewidePack(collections)}
            {renderSocialActionPack(collections)}
            {renderWorkerPack(collections)}
            {renderSustainablePack(collections)}
            {renderCreativeAttributesFramework(collections)}
            {renderInterculturalCooperation(collections)}
          </>
        )}

        {selectedCollection && selectedCollection.name === 'Transcultural and Collaborative Practices (UNESCO Intercultural Competencies)' && renderTransculturalPack()}
      </div>
    </div>
  );
}
export default BackpackListPage;
