import { IoTriangleSharp } from 'react-icons/io5';
import { FaSquareFull } from 'react-icons/fa';
import { BsFillCircleFill } from 'react-icons/bs';

import { LIGHT_COLOR_CATEGORIES } from '../../configs/Card';

const renderCard = (card, index) => {
  const isTextBlack = LIGHT_COLOR_CATEGORIES.includes(card.category);
  return (
    <div className={`mr-8 font-bold text-md w-24 flex justify-center items-center text-center ${isTextBlack ? 'text-black' : 'text-white'}`} key={`card-tooltip-${index}`}>
      {card.competency}
    </div>
  );
};

const SubCategoryCard = ({ subCategory, setSelectedSubCategory, selectedCategory, cards, setHoveringSubCategory }) => {
  return (
    <div
      className={`w-64 h-56 border-black ${subCategory.isHovering ? 'bg-zinc-700 z-20' : 'bg-white'} drop-shadow-xl p-4 flex flex-col justify-between m-4 cursor-pointer opacity-100`}
      onClick={() => setSelectedSubCategory(subCategory.name)}
    >
      <div>
        <h3 className='font-semibold text-lg mb-2'>{subCategory.name}</h3>
        <h3 className='text-gray-600'>{selectedCategory.name}</h3>
      </div>

      <div
        onMouseEnter={() => setHoveringSubCategory(subCategory.name)}
        onMouseLeave={() => setHoveringSubCategory(undefined)}
      >
        {subCategory.isHovering && (
          <div className="relative bg-red-500">
            <div
              className="absolute -top-4 p-4 -mt-1 text-sm leading-tight text-white transition ease-in duration-300 transform -translate-x-1/3 -translate-y-full rounded shadow-lg flex"
              style={{ backgroundColor: selectedCategory.color }}
            >
              {cards.map((card, index) => renderCard(card, index))}
            </div>
          </div>
        )}

        {subCategory.shape === 'Circle' && (<BsFillCircleFill className={`w-8 h-8 fill-white`} style={{ fill: selectedCategory.color }} />)}
        {subCategory.shape === 'Triangle' && (<IoTriangleSharp className={`w-8 h-8 fill-white`} style={{ fill: selectedCategory.color }} />)}
        {subCategory.shape === 'Square' && (<FaSquareFull className='w-8 h-8 fill-white' style={{ fill: selectedCategory.color }} />)}
      </div>
    </div>
  );
}
export default SubCategoryCard;
