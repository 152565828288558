import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextTransition, { presets } from "react-text-transition";
import { BsArrowRight } from 'react-icons/bs';
import { FiPlay } from 'react-icons/fi';
import { Transition, Dialog } from '@headlessui/react';

import Footer from '../components/Footer';
import Header from '../components/Header';

const LandingPage = () => {
  const navigate = useNavigate();

  const [isShowIntroModal, setIsShowIntroModal] = useState(false);

  const [index, setIndex] = useState(0);
  const TEXTS = [
    "Reflect on ",
    "Evaluate ",
    "Plan "
  ];
  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      3000
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Transition appear show={isShowIntroModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsShowIntroModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-screen w-screen lg:w-3/4 max-h-screen h-[600px] lg:h-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/FA5HcdHyO28?autoplay=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen></iframe>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Header isFixedPosition={false} />

      <main className="container py-20 flex flex-col items-center justify-center mb-20">
        {/* <h4 className='mb-4 font-semibold'>👋🏼 Hi, Welcome to REBEL Toolkit!</h4> */}
        <div className="text-5xl font-[AvenirBlack] mb-3 text-center">
          <TextTransition springConfig={presets.gentle} inline className={`${index === 0 ? 'mr-12' : 'mr-4'}`}>
            {TEXTS[index % TEXTS.length]}
          </TextTransition>your experiences
        </div>
        <h2 className="text-lg font-[AvenirBook] mb-20 text-center">Using Recognition of Experience Based Learning (REBEL)</h2>

        <button
          className='bg-black text-white px-4 py-3  font-semibold rounded flex items-center relative mb-3'
          onClick={() => navigate('/app')}
        >
          <p className="pr-8">Get started</p>
          <div className="absolute left-0 w-full h-full transition ease-in-out delay-150 hover:translate-x-1 duration-300 flex items-center justify-end pr-4">
            <BsArrowRight size={24} className="" />
          </div>
        </button>
        <div className='flex items-center cursor-pointer hover:underline mb-20' onClick={() => setIsShowIntroModal(true)}>
          <FiPlay className='mr-2' />
          See how it works
        </div>

        <div className='w-full lg:w-3/4 h-[300px] md:h-[600px] bg-black rounded-lg p-2'>
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/wTM0NnB6CPU" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className=''>

        </div>
      </main>

      <Footer />
    </div>
  );
}
export default LandingPage;
