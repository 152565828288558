import { useState, useEffect } from "react";
import jwt_decode from 'jwt-decode';

// TODO: Move to context
export const useUser = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) return;
    const decodedAccessToken = jwt_decode(accessToken);
    if (decodedAccessToken.user) setIsLoggedIn(true);
    if (decodedAccessToken.firstName) setFirstName(decodedAccessToken.firstName);
    if (decodedAccessToken.lastName) setLastName(decodedAccessToken.lastName);
  }, []);

  return { isLoggedIn, firstName, lastName };
}
