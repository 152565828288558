import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FiChevronsRight, FiFileText, FiLink } from 'react-icons/fi';

import { CardWithoutSelectStage }from '../../components/cards/Card';
import Header from '../../components/Header';
import Api from '../../configs/Api';
import { useUser } from '../../hooks/useUser';

const CardSummaryPage = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { isLoggedIn } = useUser();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Loading a result...');
  const [isCopiedUrl, setIsCopiedUrl] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    Api.get(`/results/${id}`)
      .then((response) => {
        setTimeout(() => {
          setResult(response.data);
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [id]);

  const onClickExportToMiro = () => {
    if (result.miroBoardId === '') {
      setLoadingMessage('Generating a Miro board...');
      setIsLoading(true);
      Api.post(
        '/cards/export-to-miro',
        { 'cards': result.cards, 'resultId': result.id },
      )
        .then(response => {
          const { miroBoardId } = response.data;
          result.miroBoardId = miroBoardId;
          setTimeout(() => {
            window.open(`/board/${miroBoardId}`, '_blank')?.focus();
            setIsLoading(false);
          }, 2000)
        })
        .catch(error => console.log(error));
    } else {
      window.open(`/board/${result.miroBoardId}`, '_blank')?.focus();
    }
  }

  const onClickCertificate = () => {
    if (!isLoggedIn) {
      navigate(`/login?redirect=${window.location.pathname}`);
      return;
    }

    if (result.certificateId === '') {
      navigate('/certificate', {state: { cards: result.cards, resultId: result.id }});
    } else {
      window.open(`/certificates/${result.certificateId}`, '_blank')?.focus();
    }
  }

  const onClickCopyUrl = () => {
    setIsCopiedUrl(true);
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => {
      setIsCopiedUrl(false);
    }, 2000);
  }

  const getDisplayedDate = (text) => {
    const datetime = new Date(text);

    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    return `${monthNames[datetime.getMonth()]} ${datetime.getDate()}, ${datetime.getFullYear()} ${datetime.getHours()}:${datetime.getMinutes()}`;
  }

  return (
    <>
      <Header isFixedPosition={true} />

      <div className="mt-20">
        {isLoading && (
          <div className="flex flex-col items-center justify-center mt-40">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-gray-400" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p className='text-gray-400 mt-2'>{loadingMessage}</p>
          </div>
        )}

        {!isLoading && (
          <div>
            <div className='container flex flex-col justify-center items-center mb-8'>
              <div className="flex flex-col md:flex-row md:w-2/3 mt-8 md:mb-4">
                <div className='w-full md:w-1/2 text-center'>
                  <h1 className='text-4xl font-semibold mb-2 md:text-left'>Summary</h1>
                  <h4 className='text-gray-500 md:text-left'>{getDisplayedDate(result.createdAt)}</h4>
                </div>
                <div className='flex justify-center md:justify-end w-full md:w-1/2 my-8 md:my-0'>
                  <button
                    className='transition bg-white hover:scale-105 duration-300 shadow-sm px-4 py-3 font-semibold rounded flex items-center'
                    onClick={onClickCertificate}
                  >
                    <FiFileText size={20} color={`#000`} />
                    <p className='pl-2'>{isLoggedIn ? (result.certificateId === '' ? 'Get certificate' : 'View certificate') : 'Log in to get a certificate'}</p>
                  </button>
                  {/* Hide Reflect feature: https://trello.com/c/Ue3wacHD/31-hide-reflect-button-for-now */}
                  {false && (
                    <button
                      className='transition bg-white hover:scale-105 duration-300 shadow-sm px-4 py-3 font-semibold rounded flex items-center'
                      onClick={onClickExportToMiro}
                    >
                      <FiChevronsRight size={20} color={`#000`} />
                      <p className='pl-2'>Reflect</p>
                    </button>
                  )}
                </div>
              </div>

              <div className='flex flex-col items-center justify-center md:w-2/3 md:grid md:grid-cols-3'>
                {result.cards.map((card, index) => (
                  <CardWithoutSelectStage key={`card-${index}`} card={card} />
                ))}
              </div>
            </div>

            <div className='text-center mb-20'>
              <button
                className='transition bg-white hover:scale-105 duration-300 shadow-sm px-4 py-3 font-semibold rounded '
                onClick={onClickCopyUrl}
              >
                <div className='flex items-center'>
                  {isCopiedUrl ? ('Copied!') : (
                    <>
                      <FiLink size={20} color={`#000`} />
                      <p className='pl-2'>Share a link</p>
                    </>
                  )}
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default CardSummaryPage;
