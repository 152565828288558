import { useNavigate, useSearchParams } from "react-router-dom";

const SignUpSuccessPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  console.log(searchParams.get('redirect'));

  return (
    <div className="h-screen bg-gray-100 flex flex-col items-center justify-center">
      <div className="font-semibold text-2xl mb-8">REBEL</div>
      <div className="bg-white p-8 shadow-xl rounded-xl w-full max-w-lg">
        <h2 className="text-2xl font-semibold mb-1">Thank you for signing up</h2>
        <p className="text-lg text-gray-500">Start explore REBEL and get the certificate now!</p>

        {searchParams.get('redirect') && (
          <button className="bg-black p-3 text-white rounded-lg w-full mt-8 cursor-pointer font-semibold" onClick={() => navigate(searchParams.get('redirect'))}>Go back to certificate</button>
        )}

        {!searchParams.get('redirect') && (
          <button className="bg-black p-3 text-white rounded-lg w-full mt-8 cursor-pointer font-semibold" onClick={() => navigate('/dashboard')}>Go to dashboard</button>
        )}

        <div className="flex justify-center mt-4">Not sure how it works? <a className="pl-1 underline cursor-pointer" href="/app">Explore REBEL</a></div>
      </div>
    </div>
  );
}
export default SignUpSuccessPage;
