import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import CustomerApi from '../../configs/CustomerApi';
import { withAuthen } from '../../hocs/withAuthen';
import { useUser } from '../../hooks/useUser';

import SponsorLogo from './images/sponsor-logo.png';

const CertificationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { firstName, lastName } = useUser();

  const [cards, setCards] = useState([]);
  const [resultId, setResultId] = useState('');
  const [codeLogos, setCodeLogos] = useState([]);
  const [certificateTitle, setCertificateTitle] = useState('');
  const [name, setName] = useState('');
  const [activity, setActivity] = useState('');
  const [code, setCode] = useState('');
  const [ableToContinue, setAbleToContinue] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const datetime = new Date().toLocaleString();

  useEffect(() => {
    if (name !== '' && activity !== '' && certificateTitle !== '') {
      setAbleToContinue(true);
    } else {
      setAbleToContinue(false);
    }
  }, [name, activity, certificateTitle]);

  useEffect(() => {
    CustomerApi.get(`/codes?text=${code}`)
      .then(res => setCodeLogos(res.data))
      .catch(err => {
        setCodeLogos([]);
        console.log(err);
      });
  }, [code]);

  useEffect(() => {
    setName(`${firstName} ${lastName}`);
  }, [firstName, lastName]);

  useEffect(() => {
    setCards(location.state.cards);
    setResultId(location.state.resultId);
  }, [location]);

  const onClickDownloadCertificate = () => {
    setIsProcessing(true);
    setAbleToContinue(false);
    CustomerApi.post(
      '/certificates',
      {
        fullName: name,
        description: activity,
        code,
        certificateTitle,
        cards,
        resultId,
      },
    )
    .then(response => {
      setIsProcessing(false);
      setAbleToContinue(true);
      navigate(`/certificates/${response.data.id}`);
    })
    .catch(error => {
      console.log(error);
      setIsProcessing(false);
      setAbleToContinue(true)
    });
  }

  return (
    <>
      <Header isFixedPosition={true} />
      <div className='min-h-screen mt-20'>
        <div className='container mb-4 flex'>
          <div className='w-1/2 hidden md:flex'>
            <div className='w-full bg-white p-4 px-2 m-8 shadow-lg flex flex-col items-center'>
              <div className='flex items-center'>
                {codeLogos.length === 0 && (
                  <div className='flex items-center'>
                    <img src={SponsorLogo} alt="" width={120} className='h-full mr-3' />
                    <img src={SponsorLogo} alt="" width={120} className='h-full mr-3' />
                    <img src={SponsorLogo} alt="" width={120} className='h-full mr-3' />
                    <img src={SponsorLogo} alt="" width={120} className='h-full mr-3' />
                  </div>
                )}

                {codeLogos.length !== 0 && codeLogos.map((logo, index) => (
                  <img key={`img-${index}`} src={logo.logoUrl} alt="" width={120} className='h-full mr-3' />
                ))}
              </div>

              <div className='font-bold text-2xl my-4'>
                {certificateTitle ? certificateTitle : 'Title'}
              </div>

              <div className='bg-gray-100 w-5/6 h-0.5 mb-4'></div>

              <div className='w-2/3 flex flex-col justify-center text-center mb-8'>
                <div className='font-semibold text-gray-600 text-2xl mb-1'>
                  {name || 'Your name'}
                </div>
                <div className='text-gray-600'>
                  {activity || 'Your activity'}
                </div>
                <div>

                </div>
              </div>

              <div className='flex flex-col mb-12'>
                {cards.map((card, index) => (
                  <p key={`card-${index}`}>
                    {card.category} & {card.subCategory}. <span className='font-bold'>{card.competency.toUpperCase()}</span>
                  </p>
                ))}
              </div>

              <div className='font-semibold text-gray-400 mb-1'>
                Certified by: rebel-tool.org
              </div>
              <div className='text-sm text-gray-400'>
                {datetime}
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/2 flex flex-col pl-0 md:pl-8'>
            <div className='pt-12'>
              <h1 className='text-4xl font-semibold mb-2'>Certificate</h1>
              <h4 className='text-gray-600 mb-8'>In order to download certificate, please complete all information</h4>
            </div>

            <label className='font-semibold mb-2'>Certificate title</label>
            <select className='border-2 border-black p-2 mb-4' onChange={e => setCertificateTitle(e.target.value)}>
              <option value="" selected disabled>Select a title...</option>
              <option value="Skills and Competencies">Skills and Competencies</option>
              <option value="Capabilities and Competencies">Capabilities and Competencies</option>
              <option value="Learning Recognition">Learning Recognition</option>
              <option value="Lifelong Learning">Lifelong Learning</option>
              <option value="Sustainable Development Principles">Sustainable Development Principles</option>
              <option value="Transcultural and Collaborative Practices">Transcultural and Collaborative Practices</option>
              <option value="Skills, Competencies, and Specialisms">Skills, Competencies, and Specialisms</option>
              <option value="Social Action and Community">Social Action and Community</option>
              <option value="Creative Attributes Framework">Creative Attributes Framework</option>
            </select>

            <label className='font-semibold mb-2'>Your full name</label>
            <input className='border-2 border-black p-2 mb-4' placeholder='Your name' value={`${firstName} ${lastName}`} onChange={e => setName(e.target.value)} />

            <label className='font-semibold mb-2'>Activity/Training experience</label>
            <textarea className='border-2 border-black p-2 mb-4' placeholder='Describe your activity while using REBEL' rows={5} onChange={e => setActivity(e.target.value)} />

            <label className='font-semibold mb-2'>Organization code (Optional)</label>
            <input className='border-2 border-black p-2 mb-8' placeholder='Code from staff' onChange={e => setCode(e.target.value)} />

            <button
              className={`${ableToContinue ? 'bg-blue-600' : 'bg-gray-300'} text-white px-4 py-3 text-md font-semibold rounded w-60`}
              onClick={ableToContinue && onClickDownloadCertificate}
            >
              {isProcessing ? 'Generating...' : 'Get a certificate' }
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default withAuthen(CertificationPage, 'customer');
