import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Transition, Menu } from '@headlessui/react';
import { FiLogOut, FiUser } from 'react-icons/fi';
import jwt_decode from 'jwt-decode';

import { API_HOST } from '../configs/AdminApi';

const Header = ({ isFixedPosition }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [dashboardUrl, setDashboardUrl] = useState('/dashboard');

  useEffect(() => {
    setEmail(localStorage.getItem('email'));
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const decodedAccessToken = jwt_decode(accessToken);
      if (decodedAccessToken.role === 'organization') {
        setDashboardUrl('/organizations/dashboard');
      }
    }

  }, []);

  return (
    <header className={`bg-white w-full ${isFixedPosition ? 'fixed top-0 shadow z-10' : ''}`}>
      <div className='container mx-auto py-4 flex justify-between'>
        <div className='flex items-center'>
          <a href="/" className='text-3xl font-bold mr-12'>REBEL</a>
          <div className='hidden md:flex'>
            <div className='font-semibold text-black mr-8 hover:underline hover:text-black cursor-pointer'>Tips</div>
            <div className='font-semibold text-black mr-8 hover:underline hover:text-black cursor-pointer'>About</div>
            <div className='font-semibold text-black mr-8 hover:underline hover:text-black cursor-pointer'>Contact</div>
          </div>
        </div>
        <div className='flex items-center'>
          {!email && (
            <>
              <a className='font-semibold text-black mr-8 hover:underline hover:text-black cursor-pointer' href={`${API_HOST}/signin/`}>Login</a>
              <button
                className='border-2 border-black px-3 py-2 font-semibold rounded hover:bg-black hover:text-white transition duration-300'
                onClick={() => navigate('/app')}
              >
                Explore REBEL
              </button>
            </>
          )}

          {email && (
            <Menu as="div" className="z-10 relative inline-block text-left">
              <Menu.Button className="">
                <div className="w-8 h-8 bg-yellow-300 flex justify-center items-center rounded-full cursor-pointer">{email && email[0].toUpperCase()}</div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {email}
                        </button>
                      )}
                    </Menu.Item>
                  </div>

                  <div className="px-1 py-1" onClick={() => navigate(dashboardUrl)}>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-gray-800 text-white' : 'text-gray-900'
                          } group flex w-full items-center rounded px-2 py-2 text-sm`}
                        >
                          {active ? (
                            <FiUser className="z-10 text-white mr-2" size={20} />
                          ) : (
                            <FiUser className="z-10 text-stone-800 mr-2" size={20} />
                          )}
                          Dashboard
                        </button>
                      )}
                    </Menu.Item>
                  </div>

                  <div className="px-1 py-1" onClick={() => navigate(`/logout?redirect=${window.location.pathname}`)}>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-gray-800 text-white' : 'text-gray-900'
                          } group flex w-full items-center rounded px-2 py-2 text-sm`}
                        >
                          {active ? (
                            <FiLogOut className="z-10 text-white mr-2" size={20} />
                          ) : (
                            <FiLogOut className="z-10 text-stone-800 mr-2" size={20} />
                          )}
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      </div>
    </header>
  );
};
export default Header;
