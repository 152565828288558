import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    localStorage.clear();

    if (searchParams.get('redirect')) navigate(searchParams.get('redirect'));
    else navigate('/login');
  }, [navigate, searchParams]);

  return (
    <>Logging out...</>
  );
}
export default Logout;
