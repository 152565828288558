import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { GoLightBulb } from 'react-icons/go';
import { IoHandRightOutline } from 'react-icons/io5';
import { IoMdHeartEmpty } from 'react-icons/io';

import Header from '../components/Header';
import SmallScreen from '../components/SmallScreen';
import Api from '../configs/Api';

const HomePage = () => {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    Api.get('/cards/categories/')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Header isFixedPosition={true} />

      <SmallScreen />

      <div className='mt-20 mb-20 hidden lg:block'>
        <Container style={{ paddingTop: 80 }}>
          <Row style={{ marginBottom: 40 }}>
            <Col>
              <h1 className="text-center font-bold text-5xl mb-4">Reflect, Evaluate, Plan</h1>
              <h4 className="text-center text-gray-500 text-lg"> </h4>
            </Col>
          </Row>

          <div className='flex justify-center text-center my-12 mb-20'>
            <div className='mr-20'>
              <button
                className='bg-black text-white px-8 py-3 text-xl font-semibold rounded mb-3'
                onClick={() => navigate('/backpacks')}
              >Preselected Packs</button>
              <div className='text-gray-600'>Edited versions and guides</div>
            </div>
            <div className=''>
              <button
                className='bg-black text-white px-8 py-3 text-xl font-semibold rounded mb-3'
                onClick={() => navigate('/card/explore')}
              >Explore REBEL</button>
              <div className='text-gray-600'>
                Complete version of <br />capabilities and compentencies
              </div>
            </div>
          </div>

          <div className='w-full flex flex-row justify-center'>
            <div className='flex flex-col mr-4'>
              <div className='bg-white flex justify-center p-4 mb-3 font-semibold text-gray-600'>Ways of Being</div>
              <div className='text-center flex justify-center'>
                {categories.filter(category => category.group === 'Ways of Being').map((category, index) => (
                  <div
                    key={`categories-${category.id}`}
                    className={`bg-white last:mr-0 mr-4 w-40 cursor-pointer drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150`}
                  >
                    <div
                      className='h-28 text-bold text-white flex justify-center items-center'
                      style={{ backgroundColor: category.color }}
                    >
                      <IoMdHeartEmpty className='w-8 h-8 opacity-60' />
                    </div>
                    <div className='p-3 text-gray-800 font-semibold'>{category.name}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className='flex flex-col mr-4'>
              <div className='bg-white flex justify-center p-4 mb-3 font-semibold text-gray-600'>Ways of Thinking</div>
              <div className='text-center flex justify-center'>
                {categories.filter(category => category.group === 'Ways of Thinking').map((category, index) => (
                  <div
                    key={`categories-${category.id}`}
                    className={`bg-white last:mr-0 mr-4 w-40 cursor-pointer drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150`}
                  >
                    <div
                      className='h-28 text-bold text-white flex justify-center items-center'
                      style={{ backgroundColor: category.color }}
                    >
                      <GoLightBulb className='w-8 h-8 opacity-60' />
                    </div>
                    <div className='p-3 text-gray-800 font-semibold'>{category.name}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className='flex flex-col'>
              <div className='bg-white flex justify-center p-4 mb-3 font-semibold text-gray-600'>Ways of Practicing</div>
              <div className='text-center flex justify-center'>
                {categories.filter(category => category.group === 'Ways of Practicing').map((category, index) => (
                  <div
                    key={`categories-${category.id}`}
                    className={`bg-white last:mr-0 mr-4 w-40 cursor-pointer drop-shadow-xl hover:shadow-xl hover:opacity-100 transition duration-150`}
                  >
                    <div
                      className='h-28 text-bold text-white flex justify-center items-center'
                      style={{ backgroundColor: category.color }}
                    >
                      <IoHandRightOutline className='w-8 h-8 opacity-60' />
                    </div>
                    <div className='p-3 text-gray-800 font-semibold'>{category.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </Container>
      </div>
    </>
  );
}
export default HomePage;
