const CategoryCard = ({ category, setSelectedCategory, selectedCategory, cards }) => {
  const isCardSelected = selectedCategory && selectedCategory.id === category.id;
  const selectedCardAmount = cards.filter(card => card.category === category.name && card.isSelected).length;

  return (
    <div
      className={`bg-white mr-4 w-40 cursor-pointer drop-shadow-xl hover:shadow-xl hover:opacity-100 ${isCardSelected ? 'opacity-100' : 'opacity-40'} transition duration-150`}
      onClick={() => setSelectedCategory(category.id)}
    >
      <div className='h-28' style={{ backgroundColor: category.color }}></div>
      <div className='p-3 text-gray-800 font-semibold'>{category.name}</div>
      {selectedCardAmount !== 0 && (
        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full" style={{ backgroundColor: '#b9ff8d', color: '#0a8926' }}>
          {selectedCardAmount}
        </span>
      )}
    </div>
  );
}
export default CategoryCard;
