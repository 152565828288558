import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import BoardPage from './pages/Board';
import AboutPage from './pages/About';
import AppPage from './pages/App';
import TipsPage from './pages/Tips';
import CardSortPage from './pages/cards/Sort';
import ContactPage from './pages/Contact';
import ExploreCardPage from './pages/cards/Explore';
import BackpackListPage from './pages/backpacks/List';
import BackpackDetailPage from './pages/backpacks/Detail';
import LandingPage from './pages/Landing';
import OnboardingOne from './pages/OnboardingOne';
import OnboardingTwo from './pages/OnboardingTwo';
import OnboardingThree from './pages/OnboardingThree';
import ResultPage from './pages/Result';
import CertificationPage from './pages/Certification';
import CertificateDetailPage from './pages/certificates/Detail';
import LoginPage from './pages/Login';
import SignUpPage from './pages/SignUp';
import Logout from './pages/Logout';
import SignUpSuccessPage from './pages/SignUpSuccess';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import TermsAndConditionsPage from './pages/TermsAndConditions';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* <Route path='/workshops/upload-material' element={<UploadMaterialPage />} exact /> */}
        <Route path='/certificate' element={<CertificationPage />} exact />
        <Route path='/certificates/:id' element={<CertificateDetailPage />} exact />
        <Route path='/card/explore' element={<ExploreCardPage />} exact />
        <Route path='/result/:id' element={<ResultPage />} exact />
        <Route path='/card/sort' element={<CardSortPage />} exact />
        <Route path='/collections/:id/backpacks/:backpackId' element={<BackpackDetailPage />} exact />
        <Route path='/backpacks' element={<BackpackListPage />} exact />
        <Route path='/board/:id' element={<BoardPage />} exact />
        <Route path='/onboardingone' element={<OnboardingOne />} exact />
        <Route path='/onboardingtwo' element={<OnboardingTwo />} exact />
        <Route path='/onboardingthree' element={<OnboardingThree />} exact />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/tips' element={<TipsPage />} />
        <Route path='/app' element={<AppPage />} />
        <Route path='/login' element={<LoginPage />} exact />
        <Route path='/logout' element={<Logout />} exact />
        <Route path='/signup/success' element={<SignUpSuccessPage />} exact />
        <Route path='/signup' element={<SignUpPage />} exact />
        <Route path='/terms-and-conditions' element={<TermsAndConditionsPage />} exact />
        <Route path='/privacy-policy' element={<PrivacyPolicyPage />} exact />
        <Route path='/' element={<LandingPage />} exact />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
