import { Navigate } from "react-router-dom";
import jwt_decode from 'jwt-decode';

// Role: customer, organization, admin
export const withAuthen = (Component, role) => {
  return (props) => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!accessToken || !refreshToken) {
      localStorage.clear();
      return <Navigate to='/login' />;
    }

    const decodedAccessToken = jwt_decode(accessToken);
    const decodedRefreshToken = jwt_decode(refreshToken);

    if (!decodedAccessToken || !decodedRefreshToken) {
      localStorage.clear();
      return <Navigate to='/login' />;
    }

    if (decodedRefreshToken.exp < Date.now() / 1000) {
      localStorage.clear();
      return <Navigate to="/login" />;
    }

    if (decodedAccessToken.role === role && decodedRefreshToken.role === role) {
      return <Component {...props} />;
    }

    localStorage.clear();
    return <Navigate to="/login" />;
  }
}
