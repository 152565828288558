import axios from 'axios';

export const API_HOST = process.env.NODE_ENV === 'production' ? 'https://api.rebel-tool.org' : 'http://localhost:8888';

const instance = axios.create({
  baseURL: `${API_HOST}/api/customers`,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});


instance.interceptors.response.use(
  (response) => (response),
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const refreshToken = localStorage.getItem("refreshToken");
      const response = await instance.post(`${API_HOST}/api/users/refresh`, { refreshToken });
      localStorage.setItem("accessToken", response.data.accessToken);

      if (response?.data.token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${response?.data.token}`,
        };
      }

      return instance(config);
    } else if (error?.response?.status === 500 && !config?.sent) {
      config.sent = true;
      localStorage.clear();
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

export default instance;
