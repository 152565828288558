import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import { RiCloseCircleLine, RiCloseLine, RiCheckLine } from 'react-icons/ri';
import { TiTickOutline } from 'react-icons/ti';
import { IoTriangleSharp } from 'react-icons/io5';
import { FaSquareFull } from 'react-icons/fa';
import { BsFillCircleFill } from 'react-icons/bs';

import Header from "../../components/Header";
import { LIGHT_COLOR_CATEGORIES } from '../../configs/Card';
import Api from '../../configs/Api';
import SmallScreen from '../../components/SmallScreen';

function Groups() {
  const location = useLocation();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [itemsWithNo, setItemsWithNo] = useState([]);
  const [itemsWithYes, setItemsWithYes] = useState([]);
  const [isCollection, setIsCollection] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setItems(location.state.cards);
    setIsCollection(location.state.isCollection === true);
  }, [location]);

  const DISCARD_CARDS_LIMIT = isCollection ? 30 : 18;
  const KEEP_CARDS_LIMIT = 6;

  const onClickNo = item => {
    if (itemsWithNo.length < DISCARD_CARDS_LIMIT){
      setItemsWithNo([item, ...itemsWithNo]);
      setItemsWithYes(itemsWithYes.filter(card => card.id !== item.id));
      setItems(items.filter(card => card.id !== item.id));
    }
  }

  const onClickYes = item => {
    if (itemsWithYes.length < 6) {
      setItemsWithYes([item, ...itemsWithYes]);
      setItemsWithNo(itemsWithNo.filter(card => card.id !== item.id));
      setItems(items.filter(card => card.id !== item.id));
    }
  }

  const onClickConfirm = () => {
    setIsProcessing(true);
    Api.post(
      '/results',
      { 'cards': itemsWithYes },
    )
      .then(response => {
        const { resultId } = response.data;
        setTimeout(() => {
          navigate(`/result/${resultId}`);
          setIsProcessing(false);
        }, 2000)
      })
      .catch(error => {
        console.log(error);
        setIsProcessing(false);
      });
  }

  const renderCard = (item, index) => {
    const isTextBlack = LIGHT_COLOR_CATEGORIES.includes(item.category)

    return (
      <div
        key={`cards-${item.id}`}
        className={`${isTextBlack ? 'text-black' : 'text-white'} drop-shadow-xl p-4 flex flex-col flex-wrap justify-between absolute inset-0`}
        style={{ backgroundColor: item.color, zIndex: -index }}
      >
        <div>
          <h3 className='font-semibold text-lg mb-2'>{item.competency}</h3>
          <h3 className=''>{item.description}</h3>
        </div>

        {item.shape === 'Circle' && (<BsFillCircleFill className={`w-8 h-8 fill-white`} />)}
        {item.shape === 'Triangle' && (<IoTriangleSharp className={`w-8 h-8 fill-white`} />)}
        {item.shape === 'Square' && (<FaSquareFull className='w-8 h-8 fill-white' />)}
      </div>
    );
  };
  const renderMiniCard = (item, type) => {
    const isTextBlack = LIGHT_COLOR_CATEGORIES.includes(item.category)

    return (
      <div
        key={`${type}-cards-${item.id}`}
        className={`w-32 h-28 ${isTextBlack ? 'text-black' : 'text-white'} drop-shadow-xl p-1 justify-between cursor-pointer text-xs group`}
        style={{ backgroundColor: item.color }}
        onClick={() => type === 'no' ? onClickYes(item) : onClickNo(item)}
      >
        <span className='hidden group-hover:block m-auto z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-center'>
          {type === 'yes' ? (<RiCloseCircleLine size={32}  style={{ fill: 'black' }}/>) : (<TiTickOutline size={32}  style={{ fill: 'black' }}/>)}
        </span>
        <div className={``}>
          <h3 className='font-semibold text-xs mb-2'>{item.competency}</h3>
          <h3 className=''>{item.description}</h3>
        </div>
      </div>
    )
  };

  return (
    <div className='h-full bg-gray-100'>
      <Header isFixedPosition={true} />

      <SmallScreen />

      <div className='hidden lg:block mt-20' >
        {isProcessing && (
          <div className="flex flex-col items-center justify-center mt-40">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-gray-400" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p className='text-gray-400 mt-2'>Generating a result...</p>
          </div>
        )}
        {!isProcessing && (
          <div className='mt-32'>
            <div className='container flex h-full pb-20' style={{minHeight: '800px'}}>
              <div className="w-1/3 border-dashed rounded-lg py-8 px-2 flex flex-col items-center">
                <h3 className='font-bold text-xl'>Reject</h3>
                <p className='mb-4'>{itemsWithNo.length}/{DISCARD_CARDS_LIMIT}</p>
                <div className='grid grid-cols-3 gap-2'>
                  {itemsWithNo.map(item => renderMiniCard(item, 'no'))}
                </div>
              </div>

              <div className='w-1/3 h-fit bg-white flex flex-col items-center p-12'>
                <div className='flex flex-col justify-center px-4 text-center mb-12'>
                  <h1 className='text-4xl font-semibold mb-2'>Refine Selection</h1>
                  <h4 className='text-gray-600'>
                    Prioritise up to 6 capabilities and competencies that apply to you and your learning
                  </h4>
                </div>

                {items.length !== 0 && (
                  <div className="h-60 w-60 relative mb-12">
                    {items.map((item, index) => (renderCard(item, index)))}
                  </div>
                )}

                {items.length !== 0 && (
                  <div className='flex'>
                    <div
                      className='bg-red-300 rounded-full w-16 h-16 flex justify-center items-center mb-2 mr-4 cursor-pointer'
                      onClick={() => onClickNo(items[0])}
                    >
                      <RiCloseLine size={40} color={`#b70000`} />
                    </div>
                    <div
                      className='bg-green-300 rounded-full w-16 h-16 flex justify-center items-center mb-2 cursor-pointer'
                      onClick={() => onClickYes(items[0])}
                    >
                      <RiCheckLine size={40} color={`#0a8926`} />
                    </div>
                  </div>
                )}

                {!isProcessing && items.length === 0 && itemsWithYes.length === KEEP_CARDS_LIMIT && (
                  <div className='w-full flex justify-between mt-20'>
                    <div className='w-32 bg-white text-center border-2 border-black rounded p-2 cursor-pointer'>
                      <h3 className='font-bold text-xl' onClick={() => navigate(-1)}>Back</h3>
                    </div>
                    <div className='w-32 text-center bg-black rounded p-2 cursor-pointer'>
                      <h3 className='font-bold text-white text-xl' onClick={onClickConfirm}>Confirm</h3>
                    </div>
                  </div>
                )}
              </div>

              <div className="w-1/3 border-dashed rounded-lg py-8 px-2 flex flex-col items-center">
                <h3 className='font-bold text-xl'>Claim</h3>
                <p className='mb-4'>{itemsWithYes.length}/6</p>
                <div className='grid grid-cols-3 gap-2'>
                  {itemsWithYes.map(item => renderMiniCard(item, 'yes'))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Groups;
