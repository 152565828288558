import { Container, Row, Col } from 'react-bootstrap';

import Header from "../components/Header";

const AboutPage = () => {
  return (
    <>
      <Header isFixedPosition={true} />

      <div style={{ backgroundColor: '#f1f1f1', minHeight: 'calc(100vh - 80px)' }}>
        <Container style={{ padding: 20 }}>
          <Row>
            <Col>
              <h1>About page!</h1>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default AboutPage;
