import Footer from "../components/Footer";
import Header from "../components/Header";
import TermsAndConditions from "../images/terms-and-conditions.png";

const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen bg-white">
      <Header isFixedPosition={false} />

      <main className="flex justify-center py-12">
        <img src={TermsAndConditions} alt="1" />
      </main>

      <Footer />
    </div>
  );
};
export default PrivacyPolicyPage;
