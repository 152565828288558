import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { IoTriangleSharp } from 'react-icons/io5';
import { FaSquareFull } from 'react-icons/fa';
import { BsFillCircleFill } from 'react-icons/bs';

import Header from '../../components/Header';
import Api from '../../configs/Api';
import { ReactComponent as CollectionIcon } from './images/collection-icon.svg';
import { ReactComponent as TriangleIcon } from './images/triangle-icon.svg';
import { ReactComponent as NonagonIcon } from './images/nonagon-icon.svg';
import { LIGHT_COLOR_CATEGORIES } from '../../configs/Card';
import SmallScreen from '../../components/SmallScreen';

const getCard = (card) => {
  const isTextBlack = LIGHT_COLOR_CATEGORIES.includes(card.category);

  // TODO: Improve UX - flipping card animation
  // NOTE: https://codepen.io/w3collective/pen/PoZyZEg
  return (
    <div className='w-64 h-56 transition duration-300 hover:rotate-y-180' style={{ transformStyle: 'preserve-3d', perspective: '256px' }} key={`card-container-${card.id}`}>
      <div
        key={`card-${card.id}`}
        className={`absolute w-full h-full ${isTextBlack ? 'text-black' : 'text-white'} drop-shadow-xl p-4 flex flex-col flex-wrap justify-between m-2`}
        style={{ backgroundColor: card.color, backfaceVisibility: 'hidden' }}
      >
        <div>
          <h3 className='font-semibold text-2xl mb-2'>{card.competency}</h3>
          <h3 className='text-sm'>{card.description}</h3>
        </div>

        {card.shape === 'Circle' && (<BsFillCircleFill className={`w-8 h-8 fill-white`} />)}
        {card.shape === 'Triangle' && (<IoTriangleSharp className={`w-8 h-8 fill-white`} />)}
        {card.shape === 'Square' && (<FaSquareFull className='w-8 h-8 fill-white' />)}
      </div>

      <div
        key={`card-back-${card.id}`}
        className={`absolute -left-4 w-full h-full bg-white text-black drop-shadow-xl p-4 flex flex-col flex-wrap justify-between m-2 rotate-y-180`}
        style={{ backfaceVisibility: 'hidden' }}
      >
        <div>
          <h3 className='font-semibold mb-2'>{card.category}</h3>
          <h3 className=''>{card.subCategory}</h3>
        </div>

        {card.shape === 'Circle' && (<BsFillCircleFill className={`w-8 h-8`} style={{ fill: card.color }} />)}
        {card.shape === 'Triangle' && (<IoTriangleSharp className={`w-8 h-8`} style={{ fill: card.color }} />)}
        {card.shape === 'Square' && (<FaSquareFull className='w-8 h-8' style={{ fill: card.color }} />)}
      </div>
    </div>
  );
}

const BackpackDetailPage = () => {
  const navigate = useNavigate();
  const { id, backpackId } = useParams();

  const [collection, setCollection] = useState();
  const [collections, setCollections] = useState([]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    Api.get(`/collections`)
      .then((response) => {
        setCollections(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    Api.get(`/collections/${id}/backpacks/${backpackId}`)
      .then((response) => {
        setCollection(response.data);
        const cardData = response.data.cards.map((card) => ({ id: card._id, isSelected: true, ...card }));
        setCards(cardData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, backpackId]);

  const PreselectedPackIcon = ({ preselectedPack }) => {
    let collectionName = '';
    collections.forEach((collection) => {
      collection.backpacks.forEach((backpack) => {
        if (backpack.id === preselectedPack._id) {
          collectionName = collection.name;
        }
      });
    });

    // TODO: Add missing icons
    if (collectionName === 'Education for Sustainable Development (UNESCO cross-cutting competencies)') {
      return (<CollectionIcon fill={collection.color} className='w-16 mr-4' />);
    } else if (collectionName === 'Lifewide Experience and Lifelong Learning') {
      return (<TriangleIcon fill={collection.color} className='w-12 mr-4' />);
    } else if (collectionName === 'Skills, Competencies and Specialisms for cultural workers and students') {
      return (<NonagonIcon fill={collection.color} className='w-16 mr-4' />);
    } else if (collectionName === 'Social Action and Communities') {
      return (<></>);
    } else if (collectionName === 'Transcultural and Collaborative Practices (UNESCO Intercultural Competencies)') {
      return (<></>);
    }
    return (<></>);
  }

  return (
    <div>
      <Header isFixedPosition={true} />

      <SmallScreen />

      {collection && (
        <div className='hidden lg:flex mt-28 mb-2 px-20 py-8 items-center justify-between'>
          <div className='cursor-pointer text-gray-500 flex justify-center items-center' onClick={() => navigate(-1)}>
            <FiArrowLeft size={20} className="mr-1" />
            Back
          </div>
          <div className='flex items-center'>
            <PreselectedPackIcon preselectedPack={collection} />
            <h3 className='text-gray-800 text-4xl font-semibold text-center'>{collection.name}</h3>
          </div>
          <div></div>
        </div>
      )}

      <div className='hidden lg:grid container w-3/4 grid grid-flow-row-dense grid-cols-4 grid-rows-2 gap-2 mb-20'>
        {cards.map((card) => getCard(card))}
      </div>

      <div className='hidden lg:flex sticky bottom-20 flex justify-end pr-8'>
        <div className='w-32 bg-white text-center border-2 border-black p-2 cursor-pointer'>
          <h3
            className='font-bold text-2xl'
            onClick={() => navigate('/card/sort', { state: { cards, isCollection: true }})}
          >
            Refine
          </h3>
        </div>
      </div>

    </div>
  );
}
export default BackpackDetailPage;
