import { useNavigate } from "react-router-dom";

const SmallScreen = () => {
  const navigate = useNavigate();

  return (
    <div className='block lg:hidden container flex flex-col justify-center items-center mt-32 px-4'>
      <h1 className='text-6xl'>👀</h1>
      <h1 className='font-bold text-xl text-gray-700 mb-1 mt-3'>We're looking good on Desktop!</h1>
      <p className='text-gray-500 text-center'>Please open the website on your PC, laptop, or bigger screen.</p>

      <button
        className={`bg-black text-white px-4 py-3 text-md font-semibold rounded w-60 mt-20 mb-2`}
        onClick={() => navigate('/organizations/certificates')}
      >
        Upload REBEL
      </button>
      <p className='text-gray-500 text-center'>Sign in to generate certificates after the workshop</p>
    </div>
  );
}
export default SmallScreen;
