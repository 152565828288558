import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../../components/Header';
import Api from '../../../configs/Api';

const CertificateDetailPage = () => {
  let { id } = useParams();

  const [certificate, setCertificate] = useState();

  useEffect(() => {
    Api.get(`/certificates/${id}`)
      .then((response) => {
        setCertificate(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <>
      <Header isFixedPosition={true} />
      <div className='mt-28'>
        <div className='container mb-4 flex'>
          {certificate && (
            <div className='w-full flex flex-col justify-center items-center p-4'>
              <img src={certificate.imageURL} alt="cert" />

              <a
                className='transition bg-white hover:scale-105 duration-300 shadow-sm px-4 py-3 font-semibold rounded mt-8'
                href={certificate.imageURL}
                download
              >
                Download a certificate
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default CertificateDetailPage;
