import { FiCheck } from 'react-icons/fi';
import { IoTriangleSharp } from 'react-icons/io5';
import { FaSquareFull } from 'react-icons/fa';
import { BsFillCircleFill } from 'react-icons/bs';
import { LIGHT_COLOR_CATEGORIES } from '../../configs/Card';

export const Card = ({ card, onClickCard = () => {} }) => {
  const isTextBlack = LIGHT_COLOR_CATEGORIES.includes(card.category);

  const renderShape = () => {
    return (
      <>
        {card.shape === 'Circle' && (<BsFillCircleFill className={`w-8 h-8 fill-white`} />)}
        {card.shape === 'Triangle' && (<IoTriangleSharp className={`w-8 h-8 fill-white`} />)}
        {card.shape === 'Square' && (<FaSquareFull className='w-8 h-8 fill-white' />)}
      </>
    );
  };

  return (
    <div
      className={`w-64 h-64 ${isTextBlack ? 'text-black' : 'text-white'} drop-shadow-xl p-4 flex flex-col flex-wrap justify-between m-4 cursor-pointer ${card.isSelected ? 'opacity-80' : 'opacity-100'}`}
      style={{ backgroundColor: card.color }}
      onClick={() => onClickCard(card)}
    >
      <div className='h-full flex flex-col justify-between text-left'>
        <div>
          <h3 className='font-semibold text-lg mb-3'>{card.competency}</h3>
          <h3 className=''>{card.description}</h3>
        </div>

        {renderShape()}
      </div>

      {card.isSelected && (
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-4'>
          <FiCheck size={40} color={`#5d5d5d`} />
        </div>
      )}
    </div>
  );
}

export const CardWithoutSelectStage = ({ card, onClickCard = () => {} }) => {
  const isTextBlack = LIGHT_COLOR_CATEGORIES.includes(card.category);

  return (
    <div
      className={`w-64 h-56 ${isTextBlack ? 'text-black' : 'text-white'} drop-shadow-xl p-4 flex flex-col flex-wrap justify-between m-4 cursor-pointer ${card.isSelected ? 'opacity-100' : 'opacity-40'}`}
      style={{ backgroundColor: card.color }}
      onClick={() => onClickCard(card)}
    >
      <div className='text-left'>
        <h3 className='font-semibold text-lg mb-3'>{card.competency}</h3>
        <h3 className=''>{card.description}</h3>
      </div>

      {card.shape === 'Circle' && (<BsFillCircleFill className={`w-8 h-8 fill-white`} />)}
      {card.shape === 'Triangle' && (<IoTriangleSharp className={`w-8 h-8 fill-white`} />)}
      {card.shape === 'Square' && (<FaSquareFull className='w-8 h-8 fill-white' />)}
    </div>
  );
}
