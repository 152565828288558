import { FiFacebook, FiLinkedin, FiTwitter, FiInstagram } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  return(
    <footer className='bg-black py-12 pb-20'>
      <div className='container mx-auto text-white grid md:grid-cols-2 lg:grid-cols-4 gap-4'>
        <div className='flex flex-col justify-between'>
          <div className='font-semibold text-3xl mb-4 cursor-pointer' onClick={() => navigate('/')}>REBEL</div>
          <div className='flex'>
            <FiFacebook className='mr-4 text-white text-xl cursor-pointer' />
            <FiTwitter className='mr-4 text-white text-xl cursor-pointer' />
            <FiInstagram className='mr-4 text-white text-xl cursor-pointer' />
            <FiLinkedin className='mr-4 text-white text-xl cursor-pointer' />
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='font-semibold text-xl mb-4'>Explore</div>
          <div className='mb-2'>All cards</div>
          <div className='mb-2'>Preselected packs</div>
        </div>
        <div className='flex flex-col'>
          <div className='font-semibold text-xl mb-4'>Use cases</div>
        </div>
        <div className='flex flex-col'>
          <div className='font-semibold text-xl mb-4'>Resources</div>
          <div className='cursor-pointer mb-2' onClick={() => navigate('/terms-and-conditions')}>Terms and Conditions</div>
          <div className='cursor-pointer mb-2' onClick={() => navigate('/privacy-policy')}>Privacy policy</div>
          <div className='cursor-pointer mb-2'>Status</div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
