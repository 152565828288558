import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Api from '../configs/Api';

const LoginPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { register, handleSubmit, formState: { errors }, getValues } = useForm();

  const onSubmit = () => {
    setIsLoading(true);
    const { firstName, lastName, email, password } = getValues();

    Api.post(
      '/users/signup',
      { firstName, lastName, email, password },
    )
    .then(response => {
      Api.post(
        '/users/login',
        { email, password },
      )
      .then(response => {
        setIsLoading(false);
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('email', response.data.email);

        setIsLoading(false);
        if (searchParams.get('redirect')) navigate(`/signup/success?redirect=${searchParams.get('redirect')}`);
        else navigate('/signup/success');
      })
      .catch(error => {
        console.log(error);
        setError('Server error, try again later');
        setIsLoading(false);
      });


    })
    .catch(error => {
      console.log(error);
      setError('Invalid email or password');
      setIsLoading(false);
    });
  };

  return (
    <div className="h-full flex">
      <div className="hidden lg:flex w-1/2 bg-right brightness-100 bg-cover bg-signup-hero flex flex-col justify-end p-12">
        <h2 className='text-white text-5xl font-semibold mb-8'>"REBEL helps me reflect and learn from my past experiences"</h2>
        <h3 className='text-white text-xl font-semibold'>Alison, Volunteer in Camden</h3>
      </div>
      <div className="w-full lg:w-1/2">
        <div className="h-screen overflow-y-auto bg-white flex flex-col items-center justify-center">
          <div className="font-bold text-3xl mb-8 cursor-pointer" onClick={() => navigate('/')}>REBEL</div>
          <div className="bg-white p-8 w-full max-w-lg">
            <h2 className="text-2xl font-semibold mb-1">Get started!</h2>
            <p className="text-lg text-gray-500 mb-6">Explore your capabilities effortlessly now.</p>

            {error && (<p className='mb-4 p-4 rounded-lg border-2 border-red-500 bg-red-100 text-red-500 font-semibold'>{error}</p>)}

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-2">
                <label className="block font-semibold text-gray-500 mb-2">First name</label>
                <input
                  type="text"
                  className={`p-3 bg-gray-100 rounded w-full ${errors.firstName && 'border-2 border-red-500'}`}
                  {...register('firstName', { required: true, maxLength: 64 })}
                />
                {errors.firstName && (
                  <>
                    {errors.firstName.type === 'required' && (<p className="p-1 text-red-500">The first name is required!</p>)}
                    {errors.firstName.type === 'maxLength' && (<p className="p-1 text-red-500">The first name is too long!</p>)}
                  </>
                )}
              </div>
              <div className="mb-2">
                <label className="block font-semibold text-gray-500 mb-2">Last name</label>
                <input
                  type="text"
                  className={`p-3 bg-gray-100 rounded w-full ${errors.lastName && 'border-2 border-red-500'}`}
                  {...register('lastName', { required: true, maxLength: 64 })}
                />
                {errors.lastName && (
                  <>
                    {errors.lastName.type === 'required' && (<p className="p-1 text-red-500">The last name is required!</p>)}
                    {errors.lastName.type === 'maxLength' && (<p className="p-1 text-red-500">The last name is too long!</p>)}
                  </>
                )}
              </div>
              <div className="mb-2">
                <label className="block font-semibold text-gray-500 mb-2">Email</label>
                <input
                  type="text"
                  className={`p-3 bg-gray-100 rounded w-full ${errors.email && 'border-2 border-red-500'}`}
                  {...register('email', { required: true, maxLength: 64, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                />
                {errors.email && (
                  <>
                    {errors.email.type === 'required' && (<p className="p-1 text-red-500">The email is required!</p>)}
                    {errors.email.type === 'maxLength' && (<p className="p-1 text-red-500">The email is too long!</p>)}
                    {errors.email.type === 'pattern' && (<p className="p-1 text-red-500">The email is not valid!</p>)}
                  </>
                )}
              </div>
              <div className="mb-2">
                <label className="block font-semibold text-gray-500 mb-2">Password</label>
                <input
                  type="password"
                  className={`p-3 bg-gray-100 rounded w-full ${errors.password && 'border-2 border-red-500'}`}
                  {...register('password', { required: true, minLength: 8, maxLength: 64 })}
                />
                {errors.password && (
                  <>
                    {errors.password.type === 'required' && (<p className="p-1 text-red-500">The password is required!</p>)}
                    {errors.password.type === 'minLength' && (<p className="p-1 text-red-500">The password is too short!</p>)}
                    {errors.password.type === 'maxLength' && (<p className="p-1 text-red-500">The password is too long!</p>)}
                  </>
                )}
              </div>
              <div className="mb-2">
                <label className="block font-semibold text-gray-500 mb-2">Confirm password</label>
                <input
                  type="password"
                  className={`p-3 bg-gray-100 rounded w-full ${errors.passwordConfirm && 'border-2 border-red-500'}`}
                  {...register('passwordConfirm', { required: true, minLength: 8, maxLength: 64, validate: value => value === getValues('password') })}
                />
                {errors.passwordConfirm && (
                  <>
                    {errors.passwordConfirm.type === 'required' && (<p className="p-1 text-red-500">The confirm password is required!</p>)}
                    {errors.passwordConfirm.type === 'minLength' && (<p className="p-1 text-red-500">The confirm password is too short!</p>)}
                    {errors.passwordConfirm.type === 'maxLength' && (<p className="p-1 text-red-500">The confirm password is too long!</p>)}
                    {errors.passwordConfirm.type === 'validate' && (<p className="p-1 text-red-500">The confirm password is not matched with the password!</p>)}
                  </>
                )}
              </div>

              <p className='text-sm pt-2 text-gray-600'>By signing up for this account you agree to the <a href="/terms-and-conditions" target={'_blank'} className="underline">Terms and Conditions</a></p>

              <input
                type="submit"
                className="bg-black p-3 text-white rounded-lg w-full mt-8 cursor-pointer font-semibold"
                value={isLoading ? 'Signing up...' : 'Sign Up'}
                disabled={isLoading}
              />
            </form>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <div className="flex justify-center mt-4">Already have an account? <a className="pl-1 underline cursor-pointer" onClick={() => navigate('/login')}>Login</a></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
