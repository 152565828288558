import { useState } from 'react';
import { LIGHT_COLOR_CATEGORIES } from '../../configs/Card';
import { CardWithoutSelectStage } from './Card';

const MiniCard = ({ card, onClickCard }) => {
  const [isShowCardDetail, setIsShowCardDetail] = useState(false);

  const isTextBlack = LIGHT_COLOR_CATEGORIES.includes(card.category);

  return (
    <div
      onMouseEnter={() => setIsShowCardDetail(true)}
      onMouseLeave={() => setIsShowCardDetail(false)}
    >
      {isShowCardDetail && (
        <div className="absolute top-4 z-100  leading-tight transform -translate-x-1/6 -translate-y-full">
          <CardWithoutSelectStage card={card} />
        </div>
      )}

    <div
      className={`w-20 h-16 min-w-20 ${isTextBlack ? 'text-black' : 'text-white'} drop-shadow-xl flex flex-col flex-wrap items-between justify-between p-1 mr-2 cursor-pointer`}
      style={{ backgroundColor: card.color }}
      onClick={() => onClickCard(card)}
    >
      <h3 className='text-xs'>{card.competency}</h3>
      {card.shape === 'Circle' && (
        <div
          className='w-4 h-4 bg-white rounded-full'
        ></div>
      )}
      {card.shape === 'Triangle' && (
        <div
          className='w-0 h-0 border-b-[16px] border-l-transparent border-l-[10px] border-r-transparent border-r-[10px] border-b-white'
        ></div>
      )}
      {card.shape === 'Square' && (
        <div
          className='w-4 h-4 bg-white'
        ></div>
      )}
    </div>
    </div>
  );
}
export default MiniCard;
